//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Form, FormItem, Input, Button, Checkbox } from "element-ui";
import { mapState, mapActions } from "vuex";
export default {
    name: "Forget",
    components: {
        [Button.name]: Button,
        [Checkbox.name]: Checkbox,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Input.name]: Input,
    },
    data() {
        return {
            user: {
                memEmail: "",
            }, //表單驗證信息
        };
    },
    watch: {},
    computed: {
        ...mapState({
            //validateMsg
            msg(state) {
                return state.validate;
            },
            config(state) {
                return state.base.configInfo;
            },
            success(state) {
                return state.base.verifyParmInfo.success;
            },
        }),
    },
    // 離開路由時判斷
    beforeRouteLeave(to, from, next) {
        this.$store.commit("base/setSuccess", false);

        next();
    },
    watch: {},
    created() {},
    methods: {
        async onForget(val) {
            this.$refs.user.validate((valid) => {
                if (valid) {
                    const obj = {
                        type: "forGet",
                        val: { memEmail: this.user.memEmail },
                    };
                    this.$store.dispatch("base/setVerifySource", obj);
                } else {
                    return false;
                }
            });
        },
    },
};
